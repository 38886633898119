// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Config
import { DEPARTURE_STATUS, TARGET_SHEET_WIDTH, TIME_FORMATS } from '../../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../../context/TaskContext';

// Component(s)
import AdditionalContent from '../../content/AdditionalContent';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ExtendedListRow from '../../generics/ExtendedListRow';
import Photo from './Photo';
import PncCheck from '../../checks/PncCheck';
import SystemChecksCompleted from '../../checks/SystemChecksCompleted';

// Util(s)
import {
  TargetCommonUtil,
  CommonUtil,
  CredibilityChecksUtil,
  DateTimeUtil,
  TargetPersonUtil,
  BaggageUtil,
} from '../../../../../../../utils';
import DocumentUtil from '../../../../../../../utils/Document/Uplift/documentUtil';
import { getMaskedValue } from '../../../../../../../utils/Masking/maskingUtil';
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';
import UpliftCommonUtil from '../../../../../../../utils/Common/Uplift/commonUtil';
import { toUTCFormattedDate } from '../../../../../../../utils/Datetime/Uplift/datetimeUtil';

// Styling
import './PersonDetails.scss';

const PersonDetails = ({ id, person, personPosition, mode, preview }) => {
  // Preview target sheet check; also helps with the PNR only target sheet;
  if (!person) {
    return null;
  }

  const { additionalContent } = useTask();

  const adjustedPersonPosition = personPosition + 1;
  const checksCompleted = CredibilityChecksUtil.getChecksWithoutPnc(person);
  const documents = TargetPersonUtil.documents(person);
  const nationalityCodeIso2 = TargetPersonUtil.nationalityCountryIso2(person);
  const nationalityCodeIso3 = TargetPersonUtil.nationalityCountryIso3(person);
  const formattedNationality = UpliftCommonUtil.format.nationality(nationalityCodeIso2);
  const photoContent = TargetCommonUtil.additionalPhotoContents(person);
  const { width } = useWindowSize();

  const toIssuedDate = (doc) => {
    if (!doc?.dateOfIssue) {
      return null;
    }

    if (doc?.dateOfIssue === getMaskedValue()) {
      return doc?.dateOfIssue;
    }

    return toUTCFormattedDate(doc?.dateOfIssue, TIME_FORMATS.UTC, TIME_FORMATS.DOCUMENT);
  };

  const personAdditionalContents = TargetCommonUtil.additionalContents(person, additionalContent, 'PASSENGER', true);
  const baggageAdditionalContents = TargetCommonUtil.additionalContents(person.baggage, additionalContent, 'BAGGAGE');

  const constructFullName = () => {
    let firstName = person?.name?.first
      .toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');
    return `${firstName} ${person?.name?.last.toUpperCase()}`;
  };

  const getMovementStatusTag = () => {
    if (!person?.passengerStatus) {
      return null;
    }

    const textType = () => {
      if (width < TARGET_SHEET_WIDTH) {
        return DEPARTURE_STATUS[person?.passengerStatus]?.code;
      }
      return DEPARTURE_STATUS[person?.passengerStatus]?.description;
    };

    return (
      <Tag
        id="summary-movement-description"
        className="tag tag--blue"
        text={textType()}
      />
    );
  };

  const getNationality = () => {
    return `${formattedNationality} - ${nationalityCodeIso3}`;
  };
  return (
    <div id="person-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-full">
        <div id="person-details-container" className="govuk-grid-row">
          <div id="person-details-display">
            <ComponentWrapper show={isMobilePortrait(width)}>
              <ExtendedListRow
                activeClass="badge-enabled"
                id={`person-${person.dateOfBirth}`}
                type="header-first"
                values={[
                  constructFullName(),
                  getMovementStatusTag(),
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}-nationality`}
                mobile
                type="body"
                values={[
                  ['Nationality', getNationality()],
                  ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}-gender`}
                mobile
                type="body"
                values={[
                  ['Gender', person?.gender?.name],
                  ['Seat number', person?.seatNumber],
                ]}
              />
              <ComponentWrapper show={photoContent}>
                <div className="person-photo">
                  <Photo
                    personPosition={adjustedPersonPosition}
                    photoContent={photoContent}
                  />
                </div>
              </ComponentWrapper>
              <AdditionalContent additions={personAdditionalContents} heading="Addition" />
              <ComponentWrapper show={documents.length > 0}>
                {documents.map((doc, index) => {
                  const iso2Code = DocumentUtil.countryOfIssue(doc);
                  const iso3Code = CommonUtil.iso3Code(iso2Code);
                  const formattedCOI = DocumentUtil.format.countryOfIssue(iso2Code);
                  const documentAdditionalContents = TargetCommonUtil.additionalContents(doc, additionalContent, 'DOCUMENT', true);

                  return (
                    <>
                      <ExtendedListRow
                        id={`person-${index}-${person?.dateOfBirth}-document-${doc?.typeDescription}`}
                        mobile
                        type="header"
                        values={[
                          doc?.typeDescription,
                          doc?.number,
                        ]}
                      />
                      <ExtendedListRow
                        id={`person-${index}-${person?.dateOfBirth}-document-issued`}
                        mobile
                        type="body"
                        values={[
                          ['Issued by', `${formattedCOI} - ${iso3Code}`],
                          ['Issue date', toIssuedDate(doc)],
                        ]}
                      />
                      <ExtendedListRow
                        id={`person-${index}-${person?.dateOfBirth}-document-valid-to`}
                        mobile
                        type="body"
                        values={[
                          ['Valid to', DateTimeUtil.format(doc?.expiry, 'DD MMM YYYY')],
                          '',
                        ]}
                      />
                      <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                    </>
                  );
                })}
              </ComponentWrapper>
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-baggage-details-header`}
                mobile
                type="header"
                values={[
                  'Baggage',
                  BaggageUtil.format.bagCount(person?.baggage?.numberOfCheckedBags),
                ]}
              />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-baggage-details`}
                mobile
                type="body"
                values={[
                  ['Weight', BaggageUtil.format.weight(person?.baggage?.weight)],
                  ['Tag numbers', person?.baggage?.tags],
                ]}
              />
              <AdditionalContent additions={baggageAdditionalContents} heading="Addition" />
            </ComponentWrapper>
            <ComponentWrapper show={!isMobilePortrait(width)}>
              <ExtendedListRow
                activeClass="badge-enabled"
                id={`person-${person.dateOfBirth}`}
                type="header-first"
                values={[
                  constructFullName(),
                  '',
                  `Task ID: ${id}`,
                  getMovementStatusTag(),
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}`}
                type="body"
                values={[
                  ['Nationality', getNationality()],
                  ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                  ['Gender', person?.gender?.name],
                  ['Seat number', person?.seatNumber],
                ]}
              />
              <AdditionalContent additions={personAdditionalContents} heading="Addition" />
              <ComponentWrapper show={documents.length > 0}>
                {documents.map((doc, index) => {
                  const iso2Code = DocumentUtil.countryOfIssue(doc);
                  const iso3Code = CommonUtil.iso3Code(iso2Code);
                  const formattedCOI = DocumentUtil.format.countryOfIssue(iso2Code);
                  const documentAdditionalContents = TargetCommonUtil.additionalContents(doc, additionalContent, 'DOCUMENT', true);

                  return (
                    <>
                      <ExtendedListRow
                        id={`person-${index}-${person?.dateOfBirth}-document-${doc?.typeDescription}`}
                        type="header"
                        values={[
                          doc?.typeDescription,
                          doc?.number,
                          '',
                          '',
                        ]}
                      />
                      <ExtendedListRow
                        id={`person-${index}-${person?.dateOfBirth}-document-issued`}
                        type="body"
                        values={[
                          ['Issued by', `${formattedCOI} - ${iso3Code}`],
                          ['Issue date', toIssuedDate(doc)],
                          ['Valid to', DateTimeUtil.format(doc?.expiry, 'DD MMM YYYY')],
                          '',
                        ]}
                      />
                      <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                    </>
                  );
                })}
              </ComponentWrapper>
              <ComponentWrapper show={person?.baggage?.numberOfCheckedBags > 0 && person?.baggage?.weight > 0}>
                <ExtendedListRow
                  id={`person-${person?.dateOfBirth}-baggage-details`}
                  type="header"
                  values={[
                    'Baggage',
                    BaggageUtil.format.bagCount(person?.baggage?.numberOfCheckedBags),
                    BaggageUtil.format.weight(person?.baggage?.weight),
                    person?.baggage?.tags,
                  ]}
                />
              </ComponentWrapper>
              <AdditionalContent additions={baggageAdditionalContents} heading="Addition" />
            </ComponentWrapper>
          </div>
          <ComponentWrapper show={photoContent && !isMobilePortrait(width)}>
            <div className="person-photo">
              <Photo personPosition={adjustedPersonPosition} photoContent={photoContent} />
            </div>
          </ComponentWrapper>
        </div>
        <div id="checks">
          <div id="left">
            <PncCheck
              person={person}
              personPosition={personPosition}
              mode={mode}
              preview={preview}
            />
          </div>
          <div id="right">
            <SystemChecksCompleted
              checksCompleted={checksCompleted}
              preview={preview}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PersonDetails.propTypes = {
  id: PropTypes.string.isRequired,
  person: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

PersonDetails.defaultProps = {
  preview: false,
};

export default memo(PersonDetails);
