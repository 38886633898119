// Global import(s)
import PropTypes from 'prop-types';
import { Heading, Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useWindowSize } from 'react-use';

// Config
import { DEPARTURE_STATUS, TARGET_SHEET_WIDTH, TIME_FORMATS } from '../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../context/TaskContext';

// Component
import AdditionalContent from '../content/AdditionalContent';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import ExtendedListRow from '../generics/ExtendedListRow';
import Photo from './airpax/Photo';
import PncCheck from '../checks/PncCheck';
import PreviousNext from '../generics/PreviousNext';
import SystemChecksCompleted from '../checks/SystemChecksCompleted';

// Util
import {
  CommonUtil,
  CredibilityChecksUtil,
  DateTimeUtil,
  TargetCommonUtil,
  BaggageUtil,
} from '../../../../../../utils';
import DocumentUtil from '../../../../../../utils/Document/Uplift/documentUtil';
import { getMaskedValue } from '../../../../../../utils/Masking/maskingUtil';
import TargetPersonUtil from '../../../../../../utils/Person/Target/targetPersonUtil';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';
import UpliftCommonUtil from '../../../../../../utils/Common/Uplift/commonUtil';
import { toUTCFormattedDate } from '../../../../../../utils/Datetime/Uplift/datetimeUtil';

// Styling
import './CoTravellers.scss';

const CoTravellers = ({ informationSheet, mode, preview }) => {
  const { additionalContent } = useTask();
  const { width } = useWindowSize();

  const coTravellers = TargetPersonUtil.coTravellers(informationSheet);
  if (!coTravellers || coTravellers.length < 1) {
    return null;
  }

  const toIssuedDate = (doc) => {
    if (!doc?.dateOfIssue) {
      return null;
    }

    if (doc?.dateOfIssue === getMaskedValue()) {
      return doc?.dateOfIssue;
    }

    return toUTCFormattedDate(doc?.dateOfIssue, TIME_FORMATS.UTC, TIME_FORMATS.DOCUMENT);
  };

  const constructFullName = (person) => {
    let firstName = person?.name?.first
      .toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');
    return `${firstName} ${person?.name?.last.toUpperCase()}`;
  };

  const getMovementStatusTag = (person) => {
    if (!person?.passengerStatus) {
      return null;
    }

    const textType = () => {
      if (width < TARGET_SHEET_WIDTH) {
        return DEPARTURE_STATUS[person?.passengerStatus]?.code;
      }
      return DEPARTURE_STATUS[person?.passengerStatus]?.description;
    };

    return (
      <Tag
        id="summary-movement-description"
        className="tag tag--blue"
        text={textType()}
      />
    );
  };

  return (
    <div id="co-travellers" className="govuk-grid-row target-info-summary-list">
      <hr />
      <ComponentWrapper show={coTravellers.length > 0}>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading id="co-travellers-header" size="l">Co-travellers</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 id="co-travellers-header" className="ho-heading-m">Co-travellers</h2>
        </ComponentWrapper>
      </ComponentWrapper>
      {coTravellers?.map((coTraveller, index) => {
        const personAdditionalContents = TargetCommonUtil.additionalContents(coTraveller, additionalContent, 'PASSENGER', true);
        const checksCompleted = CredibilityChecksUtil.getChecksWithoutPnc(coTraveller);
        const documents = TargetPersonUtil.documents(coTraveller) || [];
        const photoContent = TargetCommonUtil.additionalPhotoContents(coTraveller);
        const nationalityCodeIso2 = TargetPersonUtil.nationalityCountryIso2(coTraveller);
        const nationalityCodeIso3 = TargetPersonUtil.nationalityCountryIso3(coTraveller);
        const formattedNationality = UpliftCommonUtil.format.nationality(nationalityCodeIso2);

        const getNationality = () => {
          return `${formattedNationality} - ${nationalityCodeIso3}`;
        };

        return (
          <div
            className="govuk-grid-row target-info-summary-list"
            id={`co-traveller-${index}`}
            key={`co-traveller-${index}`}
          >
            <div className="govuk-grid-column-full">
              <PreviousNext
                id="co-travellers"
                index={index}
                size={coTravellers.length}
              />
              <Heading size="s">Co-traveller {index + 1}</Heading>
              <div className="co-travellers-container govuk-grid-row">
                <div className="co-travellers-display">
                  <ComponentWrapper show={!isMobilePortrait(width)}>
                    <ExtendedListRow
                      activeClass="badge-enabled"
                      id={`person-${coTraveller?.dateOfBirth}`}
                      type="header-first"
                      values={[
                        constructFullName(coTraveller),
                        '',
                        '',
                        getMovementStatusTag(coTraveller),
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}`}
                      type="body"
                      values={[
                        ['Nationality', getNationality()],
                        ['Date of birth', DateTimeUtil.format(coTraveller?.dateOfBirth, 'DD MMM YYYY')],
                        ['Gender', coTraveller?.gender?.name],
                        ['Seat number', coTraveller?.seatNumber],
                      ]}
                    />
                    <AdditionalContent additions={personAdditionalContents} heading="Addition" />
                    <ComponentWrapper show={documents.length > 0}>
                      {documents.map((doc) => {
                        const iso2Code = DocumentUtil.countryOfIssue(doc);
                        const iso3Code = CommonUtil.iso3Code(iso2Code);
                        const formattedCOI = DocumentUtil.format.countryOfIssue(iso2Code);
                        const documentAdditionalContents = TargetCommonUtil.additionalContents(doc, additionalContent, 'DOCUMENT', true);

                        return (
                          <React.Fragment key={`document-${doc.number}`}>
                            <ExtendedListRow
                              id={`person-${index}-${coTraveller?.dateOfBirth}-document-${doc?.typeDescription}`}
                              type="header"
                              values={[
                                doc?.typeDescription,
                                doc?.number,
                              ]}
                            />
                            <ExtendedListRow
                              id={`person-${coTraveller?.dateOfBirth}-issued`}
                              type="body"
                              values={[
                                ['Issued by', `${formattedCOI} - ${iso3Code}`],
                                ['Issue date', toIssuedDate(doc)],
                                ['Valid to', DateTimeUtil.format(doc?.expiry, 'DD MMM YYYY')],
                                '',
                              ]}
                            />
                            <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                          </React.Fragment>
                        );
                      })}
                    </ComponentWrapper>
                    <ComponentWrapper show={coTraveller?.baggage?.numberOfCheckedBags > 0 && coTraveller?.baggage?.weight > 0}>
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-details`}
                        type="header"
                        values={[
                          'Baggage',
                          BaggageUtil.format.bagCount(coTraveller?.baggage?.numberOfCheckedBags),
                          BaggageUtil.format.weight(coTraveller?.baggage?.weight),
                          coTraveller?.baggage?.tags,
                        ]}
                      />
                    </ComponentWrapper>
                  </ComponentWrapper>
                  <ComponentWrapper show={isMobilePortrait(width)}>
                    <ExtendedListRow
                      activeClass="badge-enabled"
                      id={`person-${coTraveller?.dateOfBirth}`}
                      mobile
                      type="header-first"
                      values={[
                        constructFullName(coTraveller),
                        getMovementStatusTag(coTraveller),
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}-nationality`}
                      mobile
                      type="body"
                      values={[
                        ['Nationality', getNationality()],
                        ['Date of birth', DateTimeUtil.format(coTraveller?.dateOfBirth, 'DD MMM YYYY')],
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}-gender`}
                      mobile
                      type="body"
                      values={[
                        ['Gender', coTraveller?.gender?.name],
                        ['Seat number', coTraveller?.seatNumber],
                      ]}
                    />
                    <ComponentWrapper show={photoContent}>
                      <div className="person-photo">
                        <Photo
                          personPosition={index}
                          photoContent={photoContent}
                        />
                      </div>
                    </ComponentWrapper>
                    <AdditionalContent additions={personAdditionalContents} heading="Addition" />
                    <ComponentWrapper show={documents.length > 0}>
                      {documents.map((doc) => {
                        const iso2Code = DocumentUtil.countryOfIssue(doc);
                        const iso3Code = CommonUtil.iso3Code(iso2Code);
                        const formattedCOI = DocumentUtil.format.countryOfIssue(iso2Code);
                        const documentAdditionalContents = TargetCommonUtil.additionalContents(doc, additionalContent, 'DOCUMENT', true);
                        return (
                          <React.Fragment key={`document-${doc.number}`}>
                            <ExtendedListRow
                              id={`person-${index}-${coTraveller?.dateOfBirth}-document-${doc?.typeDescription}`}
                              mobile
                              type="header"
                              values={[
                                doc?.typeDescription,
                                doc?.number,
                              ]}
                            />
                            <ExtendedListRow
                              id={`person-${coTraveller?.dateOfBirth}-issued`}
                              mobile
                              type="body"
                              values={[
                                ['Issued by', `${formattedCOI} - ${iso3Code}`],
                                '',
                              ]}
                            />
                            <ExtendedListRow
                              id={`person-${coTraveller?.dateOfBirth}-valid-to`}
                              mobile
                              type="body"
                              values={[
                                ['Valid to', DateTimeUtil.format(doc?.expiry, 'DD MMM YYYY')],
                                ['Issue date', toIssuedDate(doc)],
                              ]}
                            />
                            <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                          </React.Fragment>
                        );
                      })}
                    </ComponentWrapper>
                    <ComponentWrapper show={coTraveller?.baggage !== null}>
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-details`}
                        mobile
                        type="header"
                        values={[
                          'Baggage',
                          BaggageUtil.format.bagCount(coTraveller?.baggage?.numberOfCheckedBags),
                        ]}
                      />
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-total`}
                        mobile
                        type="body"
                        values={[
                          BaggageUtil.format.weight(coTraveller?.baggage?.weight),
                          coTraveller?.baggage?.tags,
                        ]}
                      />
                    </ComponentWrapper>
                  </ComponentWrapper>
                </div>
                <ComponentWrapper show={photoContent && !isMobilePortrait(width)}>
                  <div className="person-photo">
                    <Photo personPosition={index} photoContent={photoContent} />
                  </div>
                </ComponentWrapper>
              </div>
              <div id="checks">
                <div id="left">
                  <PncCheck
                    person={coTraveller}
                    personPosition={index + 1}
                    mode={mode}
                    preview={preview}
                  />
                </div>
                <div id="right">
                  <SystemChecksCompleted checksCompleted={checksCompleted} preview={preview} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CoTravellers.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

CoTravellers.defaultProps = {
  preview: false,
};

export default CoTravellers;
