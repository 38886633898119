import {
  ButtonGroup,
  Button,
  Heading,
  NotificationBanner,
} from '@ukhomeoffice/cop-react-components';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Config
import QUERY_KEYS from '../../../../../../../utils/Hooks/constants';
import config from '../../../../../../../utils/config';
import { API_VERSIONS, LOCAL_STORAGE_KEYS, OPERATION, RECIPIENT_TYPE, SUB_MODES, TASK_STATUS } from '../../../../../../../utils/constants';
import { COMPONENT_IDS, GA_EXCLUDED_COMPONENTS, AIRPAX_EXCLUDED_COMPONENTS } from './constants';

// Context(s)
import { useErrorAlert } from '../../../../../../../context/ErrorAlertContext';
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useView } from '../../../../../../../context/ViewContext';
import { useTask } from '../../../../../../../context/TaskContext';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Component(s)
import AirpaxCreateTarget from './airpax/CreateTarget';
import IdpUpdateRecipient from './components/idp/IdpUpdateRecipient';
import CancelEditModal from '../../../CancelEditModal';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import GeneralAviationCreateTarget from './general_aviation/CreateTarget';
import IDPCreateTarget from './idp/CreateTarget';
import LinkButton from '../../../../../../../components/Buttons/LinkButton';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import ModifyRecipient from './ModifyRecipient';
import OutcomeCaption from './components/OutcomeCaption';
import OutcomeMessage from '../prompts/OutcomeMessage';
import PreviewTargetSheetPage from '../../../../../../Target/TargetSheetPage/PreviewTargetSheetPage';
import WithdrawTarget from './WithdrawTarget';
import WithdrawAlertButton from '../buttons/WithdrawAlertButton';
import WithdrawTargetButton from '../buttons/WithdrawTargetButton';
import HandlingInstructions from '../../../../../../Target/TargetSheetPage/components/movement/information/HandlingInstructions';

// Hook(s)
import useFetchInformationSheet from '../../../../../../../utils/Hooks/useFetchInformationSheet';
import useFetchTargetRecipients from '../../../../../../../utils/Hooks/useFetchTargetRecipients';
import useHideBackLink from '../../../../../../../utils/Hooks/useHideBackLink';
import useScreenSize from '../../../../../../../utils/Hooks/useScreenSize';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';

// Util(s)
import toAutopopulationPayload, { toRecipients } from './helper/toAutopopulationPayload';
import toSubmissionPayload from './helper/toSubmissionPayload';
import validateSubmission, { validateSubmissionForGA } from './validate/validateSubmission';
import { CommonUtil } from '../../../../../../../utils';
import { isGaSubMode, isIdpSubMode } from '../../../../../../../utils/Task/taskUtil';
import { getCredibilityChecksPayload } from '../../../helper/updateCredibilityChecks';
import TargetMovementUtil from '../../../../../../../utils/Movement/Target/targetMovementUtil';

// Styling
import './CreateTarget.scss';

const UPDATE_PNR_KEY = 'update-pnr-key';

const CreateTarget = ({ actionType, headingLabel, submitLabel, onClose, pnrSelections, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const queryClient = useQueryClient();
  const [submitted, setSubmitted] = useState(false);
  const [addActionOption, setAddActionOption] = useState({
    show: false,
    type: OPERATION.ADD,
    previouslySelectedRecipient: null,
  });
  const [recipientType, setRecipientType] = useState(RECIPIENT_TYPE.FRONTLINE);
  const [formData, setFormData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [componentErrors, setComponentErrors] = useState(null);
  const [previewTargetSheet, setPreviewTargetSheet] = useState(false);
  const [previousRecipients, setPreviousRecipients] = useState([]);
  const [withdrawnRecipients, setWithdrawnRecipients] = useState([]);
  const [isWithdrawTargetFormOpen, setIsWithdrawTargetFormOpen] = useState(false);
  const { setErrors } = useErrorAlert();
  const { taskId: targetId,
    setIsEditTarget,
    isEditTarget,
    task,
    setIsUpdateTask,
    setIsAddDetail,
    isUpdateTask,
    isAddDetail,
    setTarget,
    subMode,
    setOriginalTask,
    credibilityChecks,
    setCredibilityChecks,
    additionalContent,
    setAdditionalContent } = useTask();
  const { targetRecipients, isLoading: isLoadingTargetRecipients } = useFetchTargetRecipients(subMode);
  const { informationSheet, isLoading: isLoadingInformationSheet } = useFetchInformationSheet(targetId);
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { isSettingView, showHandlingInstructions } = useView();
  const mode = TargetMovementUtil.mode(informationSheet);
  useSetTabTitle(headingLabel);
  useHideBackLink();

  let targetPageName = 'target';
  if (isGaSubMode(subMode)) {
    targetPageName = 'alert';
  }

  const toContainerClass = () => {
    return classNames(`govuk-grid-column-${isNineteenTwentyOrMorePixels ? 'one-third' : 'two-thirds'}`);
  };

  const addNestedComponentIdsIfRequired = (componentIds) => {
    if (formData?.[COMPONENT_IDS.ABUSE_TYPES]?.includes('OTHER')) {
      return [...componentIds, COMPONENT_IDS.OTHER_TYPES];
    }

    if (formData?.[COMPONENT_IDS.REFERRAL_REASON]?.includes('OTHER')) {
      return [...componentIds, COMPONENT_IDS.OTHER_TYPES_IDP];
    }
    return componentIds;
  };

  const getComponentIdsToValidate = () => {
    const COMPONENTS_TO_EXCLUDE = isGaSubMode(subMode) ? GA_EXCLUDED_COMPONENTS : AIRPAX_EXCLUDED_COMPONENTS;
    let componentIds = Object.values(COMPONENT_IDS)
      .filter((componentId) => !COMPONENTS_TO_EXCLUDE.includes(componentId))
      .filter((componentId) => typeof componentId === 'string');
    componentIds = addNestedComponentIdsIfRequired(componentIds);
    return componentIds;
  };

  const validate = () => {
    const componentIds = getComponentIdsToValidate();
    const { hasErrors, errors } = isGaSubMode(subMode)
      ? validateSubmissionForGA(formData, componentIds)
      : validateSubmission(formData, componentIds);
    if (!hasErrors) {
      setErrors(null); // Clear previous errors
      return true;
    }

    setComponentErrors(errors);
    setErrors(Object.keys(errors).map((key) => {
      return {
        id: key,
        error: errors[key]?.summary,
      };
    }));
    return false;
  };

  const performRequest = async (submissionPayload) => {
    if (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED) {
      return AxiosRequests.updateTarget(apiClient, targetId, submissionPayload);
    }

    return AxiosRequests.createTarget(apiClient, submissionPayload, API_VERSIONS.V4);
  };

  const onInternalSubmit = useCallback(async () => {
    if (!validate()) {
      return;
    }

    localStorage.removeItem(LOCAL_STORAGE_KEYS.PNR_SELECTIONS);

    await performRequest(toSubmissionPayload(targetId, formData, additionalContent, getCredibilityChecksPayload(credibilityChecks), subMode))
      .then((targetData) => {
        setSubmitted(true);
        setTarget(targetData.target);
        setCredibilityChecks(null);
        setAdditionalContent(null);
        setOriginalTask(null);
        setIsUpdateTask(false);
      })
      .catch(() => {
        // This is intentional
      });
  }, [formData]);

  const updateWithdrawnRecipientsIfRequired = () => {
    const previousTargetRecipients = previousRecipients.map((recipient) => recipient.displayname);
    const newTargetRecipients = formData?.[COMPONENT_IDS.RECIPIENTS]?.map((recipient) => recipient.displayname) || [];
    if ((isAddDetail || isEditTarget) && !isEqual(previousTargetRecipients, newTargetRecipients)) {
      setWithdrawnRecipients(
        previousTargetRecipients
          .map((previousTargetRecipient) => {
            if (!newTargetRecipients.includes(previousTargetRecipient)) {
              return previousRecipients.find(({ displayname }) => displayname === previousTargetRecipient);
            }
            return null;
          })
          .filter((item) => !!item),
      );
      return;
    }
    setWithdrawnRecipients([]);
  };

  const onRemoveRecipient = useCallback((previouslySelectedRecipient) => {
    setRecipientType(RECIPIENT_TYPE.FRONTLINE);
    setFormData((prev) => {
      return {
        ...prev,
        targetRecipients: formData?.targetRecipients.filter((targetRecipient) => {
          if (subMode === SUB_MODES.IDP) {
            return targetRecipient.email !== previouslySelectedRecipient;
          }
          return targetRecipient.customName !== previouslySelectedRecipient;
        }),
      };
    });
  }, [formData]);

  const onRemoveBorderRecipient = useCallback((previouslySelectedRecipient) => {
    setRecipientType(RECIPIENT_TYPE.BORDER);
    setFormData((prev) => {
      return {
        ...prev,
        borderOfficers: formData?.borderOfficers.filter((borderOfficer) => {
          return borderOfficer.email !== previouslySelectedRecipient;
        }),
      };
    });
  }, [formData]);

  const onChangeRecipient = useCallback((previouslySelectedRecipient) => {
    setRecipientType(RECIPIENT_TYPE.FRONTLINE);
    setAddActionOption((prev) => {
      return {
        ...prev,
        show: true,
        type: OPERATION.CHANGE,
        previouslySelectedRecipient,
      };
    });
  }, [formData, addActionOption]);

  const onChangeBorderRecipient = useCallback((previouslySelectedRecipient) => {
    setRecipientType(RECIPIENT_TYPE.BORDER);
    setAddActionOption((prev) => {
      return {
        ...prev,
        show: true,
        type: OPERATION.CHANGE,
        previouslySelectedRecipient,
      };
    });
  }, [formData, addActionOption]);

  const onRevertRecipients = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      [COMPONENT_IDS.RECIPIENTS]: previousRecipients,
    }));
  }, [formData, previousRecipients]);

  const toWithdrawnFrontlineRecipients = () => {
    const recipientsToWithdrawTargetFrom = withdrawnRecipients.map(({ displayname }) => displayname);
    const lastItem = recipientsToWithdrawTargetFrom.pop() || '';

    if (recipientsToWithdrawTargetFrom.length) {
      return `${recipientsToWithdrawTargetFrom.join(', ')} and ${lastItem}`;
    }

    return lastItem;
  };

  const onChange = useCallback((data) => {
    setFormChanged(true);
    if (data?.target) {
      const { target } = data;
      setFormData((prev) => {
        return { ...prev, [target.name]: target.value };
      });
      return;
    }
    setFormData(data);
  }, [formData]);

  const isAnyPreviousRecipientNoLongerInCurrentRecipients = () => {
    const currentRecipientNames = formData?.[COMPONENT_IDS.RECIPIENTS]?.map((recipient) => recipient?.displayname)?.sort() || [];
    const previousRecipientNames = previousRecipients?.map((recipient) => recipient?.displayname)?.sort() || [];
    return previousRecipientNames.some((previousRecipient) => !currentRecipientNames.includes(previousRecipient));
  };

  const onCancelConfirmation = () => {
    setShowCancelModal(true);
  };

  const onCancel = () => {
    if (!formChanged) {
      setErrors(null);
      setIsEditTarget(false);
      onClose();
      return;
    }

    onCancelConfirmation();
  };

  const getRecipients = () => {
    return [
      ...formData?.targetRecipients.map((recipient) => ({ customName: recipient.customName })),
      ...formData?.borderOfficers.map((borderOfficer) => ({ customName: borderOfficer.email })),
    ];
  };

  const updatePNRSelections = () => {
    let truePnrSelections = pnrSelections;
    if (truePnrSelections === null) {
      truePnrSelections = formData?.selectedPnrElements;
    }
    const selectedPnrElements = truePnrSelections?.map(({ type }) => type);
    if (!selectedPnrElements) {
      return;
    }

    if (!formData?.selectedPnrElements) {
      localStorage.setItem(UPDATE_PNR_KEY, true);
      setFormData((prev) => ({
        ...prev,
        selectedPnrElements,
      }));
    }
  };

  useEffect(() => {
    setPreviousRecipients(toRecipients(informationSheet?.recipients));
    setFormData(toAutopopulationPayload(informationSheet));
  }, [informationSheet]);

  useEffect(() => {
    if (!localStorage.getItem(UPDATE_PNR_KEY)) {
      updateWithdrawnRecipientsIfRequired();
    } else {
      localStorage.removeItem(UPDATE_PNR_KEY);
    }
  }, [formData]);

  useEffect(() => {
    updatePNRSelections();
  });

  if (isLoadingTargetRecipients || isLoadingInformationSheet) {
    return <LoadingSpinner />;
  }

  if (showHandlingInstructions) {
    return <HandlingInstructions showBackLink />;
  }

  if (submitted) {
    let keyword = 'Target';
    let outcomeMessageTitle = `${keyword} sent to`;
    let updatedTabTitle = TAB_TITLES.TARGET_SENT_TO;
    const recipients = getRecipients();

    if (isGaSubMode(subMode)) {
      keyword = 'Alert';
      updatedTabTitle = TAB_TITLES.ALERT_SENT_TO;
      if (isUpdateTask) {
        updatedTabTitle = TAB_TITLES.ALERT_UPDATED_REISSUED;
      }
    }

    if (!isGaSubMode(subMode) && (isEditTarget || isAddDetail)) {
      outcomeMessageTitle = `${keyword} updated and reissued to`;
      updatedTabTitle = TAB_TITLES.TARGET_UPDATED_REISSUED;
    }

    return (
      <>
        <OutcomeMessage
          title={outcomeMessageTitle}
          tabTitle={updatedTabTitle}
          onCloseTabTitle={headingLabel}
          caption={<OutcomeCaption recipients={recipients} withdrawnRecipients={withdrawnRecipients} />}
          onBackToTask={async () => {
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASK] });
            setOriginalTask(null);
            setIsAddDetail(false);
            onClose();
          }}
          onBackToTaskList={() => {
            setFormData(null);
            navigate(CommonUtil.getListPath(location.pathname));
          }}
        />
      </>
    );
  }

  if (isWithdrawTargetFormOpen) {
    return (
      <WithdrawTarget
        id="withdraw-target-container"
        targetId={targetId}
        recipients={formData[COMPONENT_IDS.RECIPIENTS]}
        onClose={() => {
          setIsWithdrawTargetFormOpen(false);
          onClose();
        }}
        onCancel={() => setIsWithdrawTargetFormOpen(false)}
      />
    );
  }

  if (addActionOption.show) {
    if (isIdpSubMode(subMode)) {
      return (
        <IdpUpdateRecipient
          formData={formData}
          setFormData={setFormData}
          previouslySelectedRecipient={addActionOption?.previouslySelectedRecipient}
          type={addActionOption.type}
          actionType={actionType}
          backTargetLabel={isUpdateTask ? `Reissue ${targetPageName}` : `Create ${targetPageName}`}
          backToCreateTarget={() => {
            setAddActionOption((prev) => {
              return {
                ...prev,
                show: false,
                type: OPERATION.ADD,
                previouslySelectedRecipient: null,
              };
            });
          }}
          targetRecipientOptions={targetRecipients}
        />
      );
    }

    return (
      <ModifyRecipient
        formData={formData}
        setFormData={setFormData}
        previouslySelectedRecipient={addActionOption?.previouslySelectedRecipient}
        type={addActionOption.type}
        actionType={actionType}
        backTargetLabel={isUpdateTask ? `Reissue ${targetPageName}` : `Create ${targetPageName}`}
        backToCreateTarget={() => {
          setAddActionOption((prev) => {
            return {
              ...prev,
              show: false,
              type: OPERATION.ADD,
              previouslySelectedRecipient: null,
            };
          });
        }}
        targetRecipientOptions={targetRecipients}
        recipientType={recipientType}
      />
    );
  }

  if (previewTargetSheet) {
    return (
      <PreviewTargetSheetPage
        backToCreateTarget={setPreviewTargetSheet}
        formData={formData}
        mode={mode}
        targetId={targetId}
        isSettingView={isSettingView}
        isSubmittedForXrayAnalysis={false}
        isSubmittingForXrayAnalysis={false}
        informationSheet={informationSheet}
      />
    );
  }

  return (
    <div className="govuk-grid-row">
      <div {...props} className={toContainerClass()}>
        <ComponentWrapper show={(isUpdateTask || isEditTarget) && isAnyPreviousRecipientNoLongerInCurrentRecipients()}>
          <NotificationBanner
            className="update-recipients-notification-banner"
            title="Important"
          >
            <span id="changed-recipient" className="govuk-body govuk-!-font-weight-bold">
              The {targetPageName} will be withdrawn for {toWithdrawnFrontlineRecipients()}
            </span>
            <br />
            <LinkButton
              id="revert-to-previous-recipients"
              className="govuk-link govuk-!-font-weight-bold"
              onClick={onRevertRecipients}
            >
              Revert to previous recipients
            </LinkButton>
          </NotificationBanner>
        </ComponentWrapper>

        <ComponentWrapper show={!isEditTarget && !isAddDetail}>
          <LinkButton
            id="back-to-task-details"
            className="govuk-back-link"
            onClick={() => onCancel(formData)}
          >
            Back to Task details
          </LinkButton>
        </ComponentWrapper>

        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-half">
            <Heading size="l">{headingLabel}</Heading>
          </div>
          <ComponentWrapper show={isUpdateTask || isEditTarget}>
            <ComponentWrapper show={!isGaSubMode(subMode)}>
              <div className={classNames('govuk-grid-column-one-half', 'govuk-!-padding-top-1', 'text-align__right')}>
                <WithdrawTargetButton onClick={() => setIsWithdrawTargetFormOpen(true)} />
              </div>
            </ComponentWrapper>
            <ComponentWrapper show={isGaSubMode(subMode)}>
              <div className={classNames('govuk-grid-column-one-half', 'govuk-!-padding-top-1', 'text-align__right')}>
                <WithdrawAlertButton onClick={() => setIsWithdrawTargetFormOpen(true)} />
              </div>
            </ComponentWrapper>
          </ComponentWrapper>
        </div>

        <ComponentWrapper show={showCancelModal}>
          <CancelEditModal
            onCancel={() => setShowCancelModal(false)}
            onProceed={() => {
              onClose();
              setErrors([]);
            }}
          />
        </ComponentWrapper>

        <ComponentWrapper show={isGaSubMode(subMode)}>
          <GeneralAviationCreateTarget
            componentErrors={componentErrors}
            formData={formData}
            onChangeRecipient={onChangeRecipient}
            onRemoveRecipient={onRemoveRecipient}
            onSetAddActionOption={setAddActionOption}
            onChange={onChange}
          />
        </ComponentWrapper>

        <ComponentWrapper show={!isGaSubMode(subMode) && !isIdpSubMode(subMode)}>
          <AirpaxCreateTarget
            componentErrors={componentErrors}
            formData={formData}
            onChangeRecipient={onChangeRecipient}
            onRemoveRecipient={onRemoveRecipient}
            onSetAddActionOption={setAddActionOption}
            onChange={onChange}
            task={task}
            recipientType={recipientType}
            onChangeBorderRecipient={onChangeBorderRecipient}
            onRemoveBorderRecipient={onRemoveBorderRecipient}
          />
        </ComponentWrapper>

        <ComponentWrapper show={isIdpSubMode(subMode)}>
          <IDPCreateTarget
            componentErrors={componentErrors}
            formData={formData}
            onChangeRecipient={onChangeRecipient}
            onRemoveRecipient={onRemoveRecipient}
            onSetAddActionOption={setAddActionOption}
            onChange={onChange}
          />
        </ComponentWrapper>

        {/* TODO: When HOIO is ready for development this link will need updating */}
        <ComponentWrapper show={isIdpSubMode(subMode)}>
          <LinkButton
            id="preview-hoio-sheet"
            className="govuk-link"
            onClick={() => setPreviewTargetSheet(!previewTargetSheet)}
          >
            Preview hoio information sheet
          </LinkButton>
        </ComponentWrapper>
        <ComponentWrapper show={!isGaSubMode(subMode)}>
          <LinkButton
            id="preview-target-sheet"
            className="govuk-link"
            onClick={() => setPreviewTargetSheet(!previewTargetSheet)}
          >
            Preview target information sheet
          </LinkButton>
        </ComponentWrapper>

        <ButtonGroup>
          <Button className="govuk-!-margin-right-3" onClick={onInternalSubmit}>
            {submitLabel}
          </Button>
          <Button classModifiers="secondary" onClick={() => onCancel(formData)}>
            Cancel
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

CreateTarget.propTypes = {
  actionType: PropTypes.string.isRequired,
  headingLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  pnrSelections: PropTypes.arrayOf(PropTypes.shape({})),
};

CreateTarget.defaultProps = {
  onClose: () => {},
  pnrSelections: null,
};

export default CreateTarget;
